<template>
    <v-container fluid class="mx-0 px-0">
        <v-row>
            <v-col cols="12">
                <v-chip-group
                    v-model="option"
                    column
                    mandatory
                >
                    <v-chip
                        v-for="(item, index) in ['Μέσοι Όροι', 'Σύνολο']"
                        :key="index"
                        filter
                        filter-icon="mdi-basketball"
                        active-class="custom-orange selected-chip"
                        color="white"
                        large
                        mandatory
                        class="text-h5 font-weight-bold"
                    >
                        {{ item }}
                    </v-chip>
                </v-chip-group>
            </v-col>
        </v-row>
        <v-row class="pb-5">
            <v-col
                v-for="([key, value], index) in Object.entries(option ? totalStats : avgStats).slice(0, 6)"
                :key="index"
                cols="4"
                md="2"
                class=" mx-0 px-5"
                style="text-transform: capitalize;"
                align="center"
            >
                <v-card v-if="!$vuetify.breakpoint.mobile" class="elevation-1 rounded-lg">
                    <div class="custom-orange white--text text-h4 secondary-font font-weight-bold py-16">
                        {{ value }}
                    </div>
                    <div class="text-h5 font-weight-bold py-8">
                        {{ key === 'blockedShots' ? 'blocks' : key }}
                    </div>
                </v-card>
                <div v-else class="circle-border">
                    <div class="secondary-font text-h6">
                        {{ value }}
                    </div>
                    <div class="custom-orange-text">
                        {{ getText(key) }}
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="[ option ? totalStats : avgStats ]"
            class="elevation-1 rounded-lg py-3 px-3"
            hide-default-footer
            disable-sort
            :mobile-breakpoint="0"
            :items-per-page="-1"
        >
            <template v-slot:item.free_throw_percentage="{ item }">
                <v-row dense align="center">
                    <v-col cols="12" align="center">
                        {{ `${((item.freeThrowsMade / (item.freeThrowAttempts ? item.freeThrowAttempts : 1)) * 100).toFixed(1)}% ${option ? `(${item.freeThrowsMade} / ${item.freeThrowAttempts})` : ''}` }}
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.two_points_percentage="{ item }">
                <v-row dense align="center">
                    <v-col cols="12" align="center">
                        {{ `${((item.twoPointsMade / (item.twoPointAttempts ? item.twoPointAttempts : 1)) * 100).toFixed(1)}% ${option ? `(${item.twoPointsMade} / ${item.twoPointAttempts})` : ''}` }}
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.three_points_percentage="{ item }">
                <v-row dense align="center">
                    <v-col cols="12" align="center">
                        {{ `${((item.threePointsMade / (item.threePointAttempts ? item.threePointAttempts : 1)) * 100).toFixed(1)}% ${option ? `(${item.threePointsMade} / ${item.threePointAttempts})` : ''}` }}
                    </v-col>
                </v-row>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
    export default {
        props: {
            totalStats: {
                type: Object,
                default: null
            },
            avgStats: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                option: 0,
                headers: [
                    { text: 'Pts', value: 'points', align: 'center' },
                    { text: '2P%', value: 'two_points_percentage', width: '150px', align: 'center' },
                    { text: '3P%', value: 'three_points_percentage', width: '150px', align: 'center' },
                    { text: 'FT%', value: 'free_throw_percentage', width: '150px', align: 'center' },
                    { text: 'OR', value: 'offensiveRebounds', align: 'center' },
                    { text: 'DR', value: 'defensiveRebounds', align: 'center' },
                    { text: 'TR', value: 'rebounds', align: 'center' },
                    { text: 'AST', value: 'assists', align: 'center' },
                    { text: 'STL', value: 'steals', align: 'center' },
                    { text: 'TO', value: 'turnovers', align: 'center' },
                    { text: 'BLK', value: 'blockedShots', align: 'center' },
                    { text: 'FC', value: 'personalFouls', align: 'center' }
                ]
            }
        },
        methods: {
            getText(key) {
                if (key === 'blockedShots') {
                    return 'blocks'
                } else if (key === 'rebounds') {
                    return 'reb.'
                } else if (key === 'turnovers') {
                    return 'turn.'
                } else {
                    return key
                }
            }
        }
    }
</script>

<style scoped>
.circle-border {
    border-radius: 50%;
    border: 3px solid orange;
    width: 90px;
    height: 90px;
    padding: 15px;
    background: #fff;
    text-align: center;
}

.text-h5 {
    font-size: 1.5rem !important;
    font-weight: 400;
    letter-spacing: normal !important;
    font-family: Arial, sans-serif !important;
}

.text-h4 {
    font-size: 2.125rem !important;
    line-height: 2.5rem;
    letter-spacing: 0.0073529412em !important;
}

.top-arc {
    border-width: 2px;
    border-radius: 10px 5% / 20px 25em 30px 35em;
    border-color: orange;
}

.v-chip::before {
    background-color: white !important;
}

.selected-chip {
    color: white;
}
</style>
