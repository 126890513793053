<template>
    <v-container fluid class="mx-0 px-0">
        <v-row>
            <v-col cols="12">
                <template>
                    <v-data-table
                        :headers="headers"
                        :items="stats"
                        class="elevation-1 rounded-lg py-3 px-3"
                        hide-default-footer
                        disable-sort
                        :mobile-breakpoint="0"
                        :items-per-page="-1"
                    >
                        <template v-slot:item.team="{ item }">
                            <v-row dense align="center">
                                <v-col cols="4" align="start">
                                    <img
                                        :src="`${assetsUrl}/${item.opponentTeamLogo}`"
                                        height="30px"
                                        contain
                                    >
                                </v-col>
                                <v-col cols="8" align="start">
                                    <span class="font-weight-bold">{{ item.opponentTeamName }}</span>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.free_throw_percentage="{ item }">
                            <v-row dense align="center">
                                <v-col cols="12" align="start">
                                    {{ ((item.freeThrowsMade / item.freeThrowAttempts) * 100).toFixed(1) }} {{ `% (${item.freeThrowsMade} / ${item.freeThrowAttempts})` }}
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.two_points_percentage="{ item }">
                            <v-row dense align="center">
                                <v-col cols="12" align="start">
                                    {{ ((item.twoPointsMade / item.twoPointAttempts) * 100).toFixed(1) }} {{ `% (${item.twoPointsMade} / ${item.twoPointAttempts})` }}
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.three_points_percentage="{ item }">
                            <v-row dense align="center">
                                <v-col cols="12" align="start">
                                    {{ ((item.threePointsMade / item.threePointAttempts) * 100).toFixed(1) }} {{ `% (${item.threePointsMade} / ${item.threePointAttempts})` }}
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-table>
                </template>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        props: {
            stats: {
                type: Array,
                default: null
            }
        },
        data() {
            return {
                team: 'Veterans BC',
                year: '2022-2023',
                phase: 'Regular Season',
                round: 'Round 1',
                assetsUrl: process.env.VUE_APP_GOOGLE_STORAGE_API,
                headers: [
                    { text: 'Αντίπαλος', value: 'team', width: '170px', align: 'start' },
                    {
                        text: 'Αγωνιστική',
                        value: 'round',
                        align: 'center'
                    },
                    { text: 'Pts', value: 'points', align: 'center' },
                    { text: '2P%', value: 'two_points_percentage', width: '150px', align: 'center' },
                    { text: '3P%', value: 'three_points_percentage', width: '150px', align: 'center' },
                    { text: 'FT%', value: 'free_throw_percentage', width: '150px', align: 'center' },
                    { text: 'OR', value: 'offensiveRebounds', align: 'center' },
                    { text: 'DR', value: 'defensiveRebounds', align: 'center' },
                    { text: 'TR', value: 'rebounds', align: 'center' },
                    { text: 'AST', value: 'assists', align: 'center' },
                    { text: 'STL', value: 'steals', align: 'center' },
                    { text: 'TO', value: 'turnovers', align: 'center' },
                    { text: 'BLK', value: 'blockedShots', align: 'center' },
                    { text: 'FC', value: 'personalFouls', align: 'center' }
                ]
            }
        }
    }
</script>
